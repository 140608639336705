<template>
  <div class="pageBox">
    <div class="oneBox">
      <h3>今天也是充满希望的一天</h3>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, toRefs } from "vue"
import { get } from "@/api/request"

export default {
  setup() {
    const data = reactive({
      numTotal: {}
    })

    const fetchData = () => {
      // get(`/api/admin/home`).then((res) => {
      //   data.numTotal = res.data
      // })
    }

    onMounted(() => {
      fetchData()
    })

    return {
      ...toRefs(data),
      fetchData
    }
  }
}
</script>

<style lang="scss" scoped>
  .oneBox{
    padding: 20px;
    width: 100%;
    text-align: center;
  }
</style>
